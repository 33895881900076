<template>
  <div class="header">
    <div class="header-container">
      <div class="flex-start-center">
        <div class="logo" @click="$router.push('/home')">
          <img src="@/assets/images/common/logo.svg" height="24px" alt="">
        </div>
        <div class="nav">
        <span :class="activeTab === '/home' ? 'active' : ''" @click.stop="linkTo('/home')">
          首页
        </span>
          <span :class="activeTab === '/product' ? 'active' : ''" class="product">
            产品
          <div class="product-menu">
            <div class="sub-menu" :class="activeTab === '/yfl/collection' ? 'active' : ''"
                 @click.stop="linkTo('/yfl/collection')">亚非拉收款</div>
            <div class="sub-menu" :class="activeTab === '/vi/chain' ? 'active' : ''" @click.stop="linkTo('/vi/chain')">垂直产业链</div>
            <!--            <div class="sub-menu" @click.stop="linkTo('/solution')">解决方案</div>-->
            <div class="sub-menu" :class="activeTab === '/mk1039' ? 'active' : ''" @click.stop="linkTo('/mk1039')">市场采购出口</div>
            <div class="sub-menu" :class="activeTab === '/tax9698' ? 'active' : ''" @click.stop="linkTo('/tax9698')">跨境电商核定</div>
          </div>
        </span>
          <!--          <span :class="activeTab == '/industry' ? 'active' : ''" @click.stop="linkTo('/industry')">-->
          <!--          AI智能运营-->
          <!--        </span>-->
          <span :class="activeTab === '/about' ? 'active' : ''" @click.stop="linkTo('/about')">
          关于数美
        </span>
        </div>
      </div>

      <div class="right-container">
        <div class="btn-box">
          <button class="login-btn" @click.stop="linkTo('/consult')">咨询</button>
          <button class="login-btn" @click.stop="openUrl('https://cb.shumeit.com/')">登录</button>
          <button class="register-btn" @click.stop="openUrl('https://cb.shumeit.com/register')">立即启用</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import request from '@/js/request.js';
export default {
  name: "Header",
  computed: {
    activeTab() {
      return this.$route.path;
    }
  },
  methods: {
    linkTo(url) {
      if (url === this.$route.path) {
        document.documentElement.scrollTop = 0
        return
      }
      this.$router.push(url)
      document.documentElement.scrollTop = 0

      // request({
      //   url: url,
      //   method: 'get'
      // }).then(res => {
      //   if(res.code != 20000) {
      //     this.$emit('needLogin');
      //   } else {
      //     this.$router.push(url);
      //   }
      // })
    },
    openUrl(url) {
      window.open(url, '_target');
    }
  }
}
</script>

<style lang="scss" scoped>
.header {
  position: fixed;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100vw;
  min-width: 900px;
  height: 80px;
  background: transparent;
  color: rgba(34, 25, 25, 0.6);
  z-index: 99;

  &.bg-class {
    background: #fff;
    border-bottom: 1px solid rgb(241, 244, 247);
  }

  .header-container {
    width: 1096px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    flex-shrink: 1;

    .logo {
      margin-right: 32px;
      cursor: pointer;
    }

    .nav {
      display: flex;

      span {
        position: relative;
        display: flex;
        align-items: center;
        margin-right: 24px;
        cursor: pointer;
        font-size: 16px;
        line-height: 24px;

        &:hover, &.active {
          color: rgb(0, 69, 156)
        }
      }

      .product {
        position: relative;

        &:hover .product-menu {
          visibility: visible;
        }

        .product-menu {
          visibility: hidden;
          transition: visibility 0.1s ease-in-out;

          position: absolute;
          top: 32px;
          right: 100%;
          transform: translateX(65%);
          padding: 4px 8px;

          width: 120px;

          border-radius: 12px;
          box-shadow: 0 38.7px 64.5px -45.15px rgba(8, 9, 48, 0.2), 0px 51.6px 103.19px -25.8px rgba(8, 9, 48, 0.1);
          background: rgb(255, 255, 255);

          .sub-menu {
            width: 120px;
            border-radius: 8px;
            padding: 4px 0;
            margin: 4px 0;

            color: rgb(34, 25, 25, 0.8);
            font-size: 16px;
            line-height: 24px;
            text-align: center;

            &:hover {
              background: rgba(0, 69, 156, 0.1);
            }

            &.active {
              background: rgba(0, 69, 156, 0.1);
              color: rgb(0, 69, 156);
            }
          }
        }
      }
    }
  }

  .right-container {
    display: flex;

    .btn-box {
      button {
        height: 40px;
        color: #fff;
        background: transparent;
        color: rgba(34, 25, 25, 0.6);
        font-family: PingFang-SC-Regular;

        & + button {
          margin-left: 24px;
          padding: 0;
        }


        &.login-btn {
          cursor: pointer;
          font-size: 16px;
          line-height: 24px;
        }

        &.register-btn {
          font-weight: 500;
          line-height: 22px;
          font-size: 16px;
          border: 0;
          cursor: pointer;
          width: 88px;
          height: 32px;
          border-radius: 4px;
          background: rgb(0, 69, 156);
          color: rgb(255, 255, 255);
        }
      }
    }
  }

}
</style>
