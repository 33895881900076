<template>
  <div class="mk1039">
    <div class="banner position-relative">
      <div class="banner-content">
        <div class="banner-title">跨境电商核定</div>

        <div class="banner-desc">跨境电商·9610/9810核定模式，应税所得率低至4%</div>
      </div>

    </div>


    <div class="policy-solution">
      <div class="policy-solution-content">
        <div class="flex-start-center">
          <img src="@/assets/images/tax9698/tax9698_data1.svg" style="width: 547px;" alt="">

          <div>
            <div class="policy-title">跨境电商<span style="color: rgb(0, 69, 156)">9610/9810政策</span></div>
            <div class="policy-desc">
              根据国家税务总局公告2019年第36号规定，在指定的跨境电子商户综合试验区内完成相关注册和备案，并通过当地海关进行电子商户出口申报手续，对于采购货物未取得的进项发票，在海外销售回款时能够按照4%的应税所得率进行阳光缴税，大大降低企业税负！
            </div>
          </div>
        </div>

        <div>
          <div class="pain-spot-title">跨境电商出口痛点</div>
          <div class="space-between pain-spot-list">
            <div class="pain-spot-item flex-column-center">
              <img src="@/assets/images/tax9698/pain_spot1.svg" style="width: 168px;" alt="">
              <div class="desc">出口电商企业采购，普遍无<br/>足额进项增值税发票</div>
            </div>

            <div class="pain-spot-item flex-column-center">
              <img src="@/assets/images/tax9698/pain_spot2.svg" style="width: 168px;" alt="">
              <div class="desc">买单出口，无法以自身主体<br/>出口报关</div>
            </div>

            <div class="pain-spot-item flex-column-center">
              <img src="@/assets/images/tax9698/pain_spot3.svg" style="width: 168px;" alt="">
              <div class="desc">资金进公账需要缴纳25%企业<br/>所得税，出口成本极大增高</div>
            </div>
          </div>


          <div class="can-do">
            <div class="title">数美能为您做什么</div>

            <div class="space-between can-do-content">
              <div>
                <div class="can-do-item flex-start">
                  <span class="item-num">01</span>
                  <div class="item-desc">一站式的综试区内的企业注册和备案，全线上化的报关功能，实现阳光报关出口
                  </div>
                </div>

                <div class="can-do-item flex-start">
                  <span class="item-num">02</span>
                  <div class="item-desc">依托银行进行合规收汇安全可靠，资金能够合法合规进入对公账户申请退税</div>
                </div>

                <div class="can-do-item flex-start">
                  <span class="item-num">03</span>
                  <div class="item-desc">按照应税所得率4%合理缴税，极大提升企业资金经营效益</div>
                </div>
              </div>

              <img src="@/assets/images/tax9698/tax9698_data2.svg" style="width: 548px;"
                   alt="">
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="why-favour">
      <div class="favour-content">
        <div class="favor-title">跨境电商核定业务流程</div>
        <div class="favor-card flex-start">
          <div class="card-item">
            <div class="img-box">
              <img src="@/assets/images/tax9698/process_card1.svg" style="width: 99px;margin-top: 54px" alt="">
            </div>

            <div class="card-text">
              <div class="card-title">公司注册</div>
            </div>
          </div>

          <div class="arrow_box flex-double-center">
            <img src="@/assets/images/mk1039/process_arrow.svg" style="width: 16px" alt="">
          </div>

          <div class="card-item">
            <div class="img-box">
              <img src="@/assets/images/tax9698/process_card2.svg" style="width: 126px;margin-top: 69px" alt="">
            </div>

            <div class="card-text">
              <div class="card-title">银行开户</div>
            </div>
          </div>

          <div class="arrow_box flex-double-center">
            <img src="@/assets/images/mk1039/process_arrow.svg" style="width: 16px" alt="">
          </div>

          <div class="card-item">
            <div class="img-box">
              <img src="@/assets/images/tax9698/process_card3.svg" style="width: 89px;margin-top: 57px" alt="">
            </div>

            <div class="card-text">
              <div class="card-title">税务登记</div>
            </div>
          </div>

          <div class="arrow_box flex-double-center">
            <img src="@/assets/images/mk1039/process_arrow.svg" style="width: 16px" alt="">
          </div>

          <div class="card-item">
            <div class="img-box">
              <img src="@/assets/images/tax9698/process_card4.svg" style="width: 132.86px;margin-top: 67px" alt="">
            </div>

            <div class="card-text">
              <div class="card-title">电子口岸备案</div>
            </div>
          </div>

          <div class="arrow_box flex-double-center">
            <img src="@/assets/images/mk1039/process_arrow.svg" style="width: 16px" alt="">
          </div>

          <div class="card-item">
            <div class="img-box">
              <img src="@/assets/images/tax9698/process_card3.svg" style="width: 89px;margin-top: 57px" alt="">
            </div>

            <div class="card-text">
              <div class="card-title">税务核定</div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div class="customer-case">
      <div class="customer-case-content">
        <div class="case-card space-between">
          <img src="@/assets/images/tax9698/case_img.png" style="width: 50%" alt=""/>
          <div class="right">
            <div class="case-title">客户案例</div>
            <div class="case-name">广州番禺区某跨境电商企业</div>

            <div class="sub-title">主要痛点</div>
            <div class="sub-desc">
              公司境内采购没有进项发票，资金长期停留在香港，若需要入境则需要承担高额税赋
            </div>

            <div class="sub-title">使用产品</div>
            <div class="sub-desc">
              跨境电商核定·9610模式
            </div>

            <div class="sub-title">解决方案</div>
            <div class="sub-desc">
              该企业通过 “9610核定征收”能够享受跨境电商特有的4%应税所得率核定征收的优惠政策，在税务合规的同时降低了税负成本
            </div>

          </div>
        </div>
      </div>
    </div>

  </div>

</template>

<script>

export default {
  name: "mk1039",
  data() {
    return {}
  },
  methods: {},
}
</script>

<style scoped lang="scss">
.banner {
  width: 100vw;
  height: 400px;
  background-image: url("~@/assets/images/tax9698/banner_bg.png");
  background-size: cover;
  background-position: right;

  .banner-content {
    width: 1096px;
    margin: 0 auto;
    padding-top: 160px;

    .banner-title {
      color: rgb(0, 0, 0);
      font-family: SourceHanSansCN-Bold;
      padding-bottom: 24px;
      font-size: 40px;
      font-weight: 700;
      line-height: 60px;
    }

    .banner-desc {
      color: rgb(34, 25, 25, 0.5);
      font-size: 18px;
      font-weight: 400;
      line-height: 28px;
    }
  }
}

.policy-solution {
  padding-top: 25.12px;

  .policy-solution-content {
    width: 1096px;
    margin: 0 auto;

    .policy-title {
      font-family: SourceHanSansCN-Regular;
      font-size: 32px;
      font-weight: 900;
      line-height: 48px;
      margin-bottom: 16px;
    }

    .policy-desc {
      color: rgb(34, 25, 25);
      font-size: 16px;
      line-height: 26px;
      width: 496px;
    }

    .pain-spot-title {
      color: rgb(0, 0, 0);
      font-family: SourceHanSansCN-Regular;
      font-size: 32px;
      font-weight: 900;
      line-height: 48px;
      margin-bottom: 24px;
    }

    .pain-spot-list {
      padding: 0 101px;
      align-items: stretch;

      .pain-spot-item {
        justify-content: flex-start;

        .desc {
          color: rgb(34, 25, 25);
          font-size: 16px;
          font-weight: 600;
          line-height: 24px;
          text-align: center;
          margin-top: 8px;
        }
      }
    }

    .can-do {
      .title {
        color: rgb(0, 0, 0);
        font-family: SourceHanSansCN-Regular;
        font-size: 32px;
        font-weight: 900;
        line-height: 48px;
        margin-top: 79px;
        margin-bottom: 40px;
      }

      .can-do-content {
        align-items: flex-start;
      }

      .can-do-item {
        padding: 10px 16px;
        width: 476px;
        margin-bottom: 12px;
        margin-left: 24px;
        cursor: default;

        &:hover {
          border-radius: 8px;
          background: linear-gradient(90.00deg, rgb(234, 238, 243) 42.52%, rgba(255, 255, 255, 0) 100%);

          .item-num {
            color: rgb(0, 69, 156);
          }

          .item-desc {
            font-weight: 500;
          }
        }

        .item-num {
          width: 39px;
          color: rgba(0, 0, 0, 0.2);
          font-family: SourceHanSansCN-Bold;
          font-size: 32px;
          line-height: 48px;
          margin-right: 24px;
        }

        .item-desc {
          color: rgb(34, 25, 25);
          font-size: 16px;
          line-height: 24px;
          width: 360px;
          height: 48px;
        }
      }
    }
  }
}

.why-favour {
  width: 100vw;
  background: rgb(32, 34, 37);

  .favour-content {
    padding-top: 80px;
    width: 1096px;
    margin: 0 auto;
    height: 512px;

    .favor-title {
      color: rgb(255, 255, 255);
      font-family: SourceHanSansCN-Regular;
      font-size: 32px;
      font-weight: 900;
      line-height: 48px;
      margin-bottom: 24px;
    }

    .favor-card {

      .arrow_box {
        width: 32px;
      }

      .card-item:last-child {
        margin-right: 0;
      }

      .card-item {
        width: 194px;
        height: 320px;
        border-radius: 24px;
        background: rgb(249, 249, 250);
        position: relative;

        .img-box {
          height: 208px;
          display: flex;
          justify-content: center;
          align-items: flex-start;
        }

        .card-text {
          padding: 0 16px;

          .card-title {
            font-family: SourceHanSansCN-Regular;
            font-size: 24px;
            font-weight: 900;
            line-height: 36px;
            margin-bottom: 8px;
            text-align: center;
          }

          .card-desc {
            color: rgb(34, 25, 25);
            font-size: 16px;
            line-height: 24px;
          }
        }
      }
    }
  }
}

.customer-case {
  border-bottom: 1px solid #f9f9fa;

  .customer-case-content {
    width: 1096px;
    margin: 0 auto;
    height: 632px;
    display: flex;
    align-items: center;

    .case-card {
      width: 100%;
      height: 424px;

      border-radius: 24px;
      box-shadow: 0 30px 50px -35px rgba(8, 9, 48, 0.2), 0px 40px 80px -20px rgba(8, 9, 48, 0.1), 0px 0px 0px 1px rgba(8, 9, 48, 0.1);

      .right {
        padding: 40px 32px;

        .case-title {
          color: rgb(0, 69, 156);
          font-family: SourceHanSansCN-Regular;
          font-size: 24px;
          font-weight: 900;
          line-height: 36px;
        }

        .case-name {
          color: rgb(0, 0, 0);
          font-family: SourceHanSansCN-Regular;
          font-size: 32px;
          font-weight: 900;
          line-height: 48px;
          margin-bottom: 24px;
        }

        .sub-title {
          color: rgb(0, 69, 156);
          font-size: 16px;
          font-weight: 600;
          line-height: 24px;
          margin-bottom: 4px;
        }

        .sub-desc {
          color: rgb(34, 25, 25);
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          margin-bottom: 16px;
        }
      }

    }
  }
}
</style>