import Vue from 'vue'
import App from './App.vue'
import router from './router'

import '@/assets/css/index.scss';
import '@/assets/fonts/font.css'

Vue.config.productionTip = false

import ElementUI from 'element-ui';    // element UI
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

import axios from 'axios';
Vue.prototype.$axios = axios;

import '@/styles/reset.css';
import '@/styles/index.css';


import BaiduMap from 'vue-baidu-map'
Vue.use(BaiduMap, {
  ak: 'CcbWpGCWjPGqGkI4CuIrX9YhH0geqsYg'
})

import Header from "./components/Header";
import Footer from "./components/Footer";

Vue.component(
  'Header', Header
)
Vue.component(
  'Footer', Footer
)

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
