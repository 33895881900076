<template>
  <div class="mk1039">
    <div class="banner position-relative">
      <div class="banner-content">
        <div class="banner-title">市场采购出口</div>

        <div class="banner-desc">市场采购贸易出口模式，阳光结汇，免税增效</div>
      </div>
    </div>


    <div class="policy-solution">
      <div class="policy-solution-content">
        <div class="flex-start-center">
          <img src="@/assets/images/mk1039/mk1039_data1.svg" style="width: 547px;" alt="">

          <div>
            <div class="policy-title">市场采购出口<span style="color: rgb(0, 69, 156)">1039政策</span></div>
            <div class="policy-desc">
              国家七部委联合发文，针对市场采购出口即1039监管方式下申报出口的经营个体户，提供外贸商户注册、市场采购备案、组货拼箱、报检通关、收汇结汇、免税申报等一站式免税出口服务，帮助小微企业享受国家1039模式试点政策红利，协助专业市场通过发展外贸新业态促进市场转型升级。
            </div>
          </div>
        </div>

        <div>
          <div class="pain-spot-title">市场采购出口痛点</div>
          <div class="space-between pain-spot-list">
            <div class="pain-spot-item flex-column-center">
              <img src="@/assets/images/mk1039/pain_spot1.svg" style="width: 168px;" alt="">
              <div class="desc">中小微出口企业无外贸资质<br/>无法独立做出口贸易</div>
            </div>

            <div class="pain-spot-item flex-column-center">
              <img src="@/assets/images/mk1039/pain_spot2.svg" style="width: 168px;" alt="">
              <div class="desc">商品采购时无法取得足额<br/>进项增值税发票</div>
            </div>

            <div class="pain-spot-item flex-column-center">
              <img src="@/assets/images/mk1039/pain_spot3.svg" style="width: 168px;" alt="">
              <div class="desc">买单出口，无法通过<br/>银行/支付机构正规收汇</div>
            </div>

            <div class="pain-spot-item flex-column-center">
              <img src="@/assets/images/mk1039/pain_spot4.svg" style="width: 168px;" alt="">
              <div class="desc">无法享受出口退税政策福利</div>
            </div>
          </div>


          <div class="can-do">
            <div class="title">数美能为您做什么</div>

            <div class="space-between can-do-content">
              <div>
                <div class="can-do-item flex-start">
                  <span class="item-num">01</span>
                  <div class="item-desc">市场采购出口服务为中小微出口企业提供了阳光化 、本地化一站式服务出口履约通道
                  </div>
                </div>

                <div class="can-do-item flex-start">
                  <span class="item-num">02</span>
                  <div class="item-desc">通过银行合法合规收汇，供应商货款完税后支持汇入经营者个人账户</div>
                </div>

                <div class="can-do-item flex-start">
                  <span class="item-num">03</span>
                  <div class="item-desc">出口商品免征不退，帮助出口企业快速提升数字化贸易竞争力</div>
                </div>
              </div>

              <img src="@/assets/images/mk1039/mk1039_data2.svg" style="width: 548px;position: relative;top: -80px"
                   alt="">
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="why-favour">
      <div class="favour-content">
        <div class="favor-title">市场采购出口业务流程</div>
        <div class="favor-card flex-start">
          <div class="card-item">
            <div class="img-box">
              <img src="@/assets/images/mk1039/process_card1.svg" style="width: 143px;margin-top: 56px" alt="">
            </div>

            <div class="card-text">
              <div class="card-title">个体工商户注册</div>
            </div>
          </div>

          <div class="arrow_box flex-double-center">
            <img src="@/assets/images/mk1039/process_arrow.svg" style="width: 16px" alt="">
          </div>

          <div class="card-item">
            <div class="img-box">
              <img src="@/assets/images/mk1039/process_card2.svg" style="width: 117px;margin-top: 48px" alt="">
            </div>

            <div class="card-text">
              <div class="card-title">海关备案、 <br/>出口免税备案</div>
            </div>
          </div>

          <div class="arrow_box flex-double-center">
            <img src="@/assets/images/mk1039/process_arrow.svg" style="width: 16px" alt="">
          </div>

          <div class="card-item">
            <div class="img-box">
              <img src="@/assets/images/mk1039/process_card3.svg" style="width: 130px;margin-top: 56px" alt="">
            </div>

            <div class="card-text">
              <div class="card-title">报关出口</div>
            </div>
          </div>

          <div class="arrow_box flex-double-center">
            <img src="@/assets/images/mk1039/process_arrow.svg" style="width: 16px" alt="">
          </div>

          <div class="card-item">
            <div class="img-box">
              <img src="@/assets/images/mk1039/process_card4.svg" style="width: 154.3px;margin-top: 64px" alt="">
            </div>

            <div class="card-text">
              <div class="card-title">供应商收汇</div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div class="customer-case">
      <div class="customer-case-content">
        <div class="case-card space-between">
          <img src="@/assets/images/mk1039/case_img.png" style="width: 50%" alt=""/>
          <div class="right">
            <div class="case-title">客户案例</div>
            <div class="case-name">深圳龙华某贸易企业</div>

            <div class="sub-title">主要痛点</div>
            <div class="sub-desc">
              公司在经营出口业务时，因缺乏对外贸易流程了解，觉得出口报关手续繁琐；同时个人私账大额收款未申报，资金来源不明，增加账户冻结风险；且无正规票据的出口交易无法享受税收优惠，加大了经营成本。
            </div>

            <div class="sub-title">使用产品</div>
            <div class="sub-desc">
              市场采购出口·1039模式
            </div>

            <div class="sub-title">解决方案</div>
            <div class="sub-desc">
              该企业通过市场采购出口的“1039”模式，在外贸金额符合业务要求的情况下，享受了政府特定对口核定优惠政策，便捷了出口实务操作，合规结汇，阳光出口。
            </div>

          </div>
        </div>
      </div>
    </div>

  </div>

</template>

<script>

export default {
  name: "mk1039",
  data() {
    return {}
  },
  methods: {},
}
</script>

<style scoped lang="scss">
.banner {
  width: 100vw;
  height: 400px;
  background-image: url("~@/assets/images/mk1039/banner_bg.png");
  background-size: cover;
  background-position: right;

  .banner-content {
    width: 1096px;
    margin: 0 auto;
    padding-top: 160px;

    .banner-title {
      color: rgb(0, 0, 0);
      font-family: SourceHanSansCN-Bold;
      padding-bottom: 24px;
      font-size: 40px;
      font-weight: 700;
      line-height: 60px;
    }

    .banner-desc {
      color: rgb(34, 25, 25, 0.5);
      font-size: 18px;
      font-weight: 400;
      line-height: 28px;
    }
  }
}

.policy-solution {
  padding-top: 25.12px;

  .policy-solution-content {
    width: 1096px;
    margin: 0 auto;

    .policy-title {
      font-family: SourceHanSansCN-Regular;
      font-size: 32px;
      font-weight: 900;
      line-height: 48px;
      margin-bottom: 16px;
    }

    .policy-desc {
      color: rgb(34, 25, 25);
      font-size: 16px;
      line-height: 26px;
      width: 496px;
    }

    .pain-spot-title {
      color: rgb(0, 0, 0);
      font-family: SourceHanSansCN-Regular;
      font-size: 32px;
      font-weight: 900;
      line-height: 48px;
      margin-bottom: 24px;
    }

    .pain-spot-list {
      padding: 0 41px;
      align-items: stretch;

      .pain-spot-item {
        justify-content: flex-start;

        .desc {
          color: rgb(34, 25, 25);
          font-size: 16px;
          font-weight: 600;
          line-height: 24px;
          text-align: center;
          margin-top: 8px;
        }
      }
    }

    .can-do {
      .title {
        color: rgb(0, 0, 0);
        font-family: SourceHanSansCN-Regular;
        font-size: 32px;
        font-weight: 900;
        line-height: 48px;
        margin-top: 79px;
        margin-bottom: 40px;
      }

      .can-do-content {
        align-items: flex-start;
      }

      .can-do-item {
        padding: 10px 16px;
        width: 476px;
        margin-bottom: 12px;
        margin-left: 24px;
        cursor: default;

        &:hover {
          border-radius: 8px;
          background: linear-gradient(90.00deg, rgb(234, 238, 243) 42.52%, rgba(255, 255, 255, 0) 100%);

          .item-num {
            color: rgb(0, 69, 156);
          }

          .item-desc {
            font-weight: 500;
          }
        }

        .item-num {
          width: 39px;
          color: rgba(0, 0, 0, 0.2);
          font-family: SourceHanSansCN-Bold;
          font-size: 32px;
          line-height: 48px;
          margin-right: 24px;
        }

        .item-desc {
          color: rgb(34, 25, 25);
          font-size: 16px;
          line-height: 24px;
          width: 360px;
          height: 48px;
        }
      }
    }
  }
}

.why-favour {
  width: 100vw;
  background: rgb(32, 34, 37);

  .favour-content {
    padding-top: 80px;
    width: 1096px;
    margin: 0 auto;
    height: 512px;

    .favor-title {
      color: rgb(255, 255, 255);
      font-family: SourceHanSansCN-Regular;
      font-size: 32px;
      font-weight: 900;
      line-height: 48px;
      margin-bottom: 24px;
    }

    .favor-card {

      .arrow_box {
        width: 32px;
      }

      .card-item:last-child {
        margin-right: 0;
      }

      .card-item {
        width: 250px;
        height: 320px;
        border-radius: 24px;
        background: rgb(249, 249, 250);
        position: relative;

        .img-box {
          height: 208px;
          display: flex;
          justify-content: center;
          align-items: flex-start;
        }

        .card-text {
          padding: 0 16px;

          .card-title {
            font-family: SourceHanSansCN-Regular;
            font-size: 24px;
            font-weight: 900;
            line-height: 36px;
            margin-bottom: 8px;
            text-align: center;
          }

          .card-desc {
            color: rgb(34, 25, 25);
            font-size: 16px;
            line-height: 24px;
          }
        }
      }
    }
  }
}

.customer-case {
  border-bottom: 1px solid #f9f9fa;

  .customer-case-content {
    width: 1096px;
    margin: 0 auto;
    height: 632px;
    display: flex;
    align-items: center;

    .case-card {
      width: 100%;
      height: 472px;

      border-radius: 24px;
      box-shadow: 0 30px 50px -35px rgba(8, 9, 48, 0.2), 0px 40px 80px -20px rgba(8, 9, 48, 0.1), 0px 0px 0px 1px rgba(8, 9, 48, 0.1);

      .right {
        padding: 40px 32px;

        .case-title {
          color: rgb(0, 69, 156);
          font-family: SourceHanSansCN-Regular;
          font-size: 24px;
          font-weight: 900;
          line-height: 36px;
        }

        .case-name {
          color: rgb(0, 0, 0);
          font-family: SourceHanSansCN-Regular;
          font-size: 32px;
          font-weight: 900;
          line-height: 48px;
          margin-bottom: 24px;
        }

        .sub-title {
          color: rgb(0, 69, 156);
          font-size: 16px;
          font-weight: 600;
          line-height: 24px;
          margin-bottom: 4px;
        }

        .sub-desc {
          color: rgb(34, 25, 25);
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          margin-bottom: 16px;
        }
      }

    }
  }
}
</style>