import axios from 'axios'
import { Message } from 'element-ui'
import { getToken } from '@/js/loginToken'

// create an axios instance
const service = axios.create({
    baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
    timeout: 30000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
      let token = getToken()
      if (token) {
          config.headers['token'] = token
      }
      return config
  },
  error => {
      console.log(error) // for debug
      return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  response => {
      const res = response.data

      // if the custom code is not 20000, it is judged as an error.
      if (res.code !== 20000) {
        Message({
          message: res.message || 'Error',
          type: 'error',
          duration: 5 * 1000
        })
        return Promise.reject(new Error(res.message || 'Error'));
      }
      return res
  },
  error => {
      console.log('err' + error) // for debug
      Message({
          message: error.message,
          type: 'error',
          duration: 5 * 1000
      })
      return Promise.reject(error)
  }
)

export default service
