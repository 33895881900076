import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        redirect: '/home'
    },
    {
        path: '/home',
        name: 'Home',
        component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue')
    },
    {
        path: '/yfl/collection',
        name: 'YflCollection',
        component: () => import(/* webpackChunkName: "about" */ '../views/YflCollection.vue')
    },
    {
        path: '/vi/chain',
        name: 'ViChain',
        component: () => import(/* webpackChunkName: "about" */ '../views/ViChain.vue')
    },
    {
        path: '/solution',
        name: 'Solution',
        component: () => import(/* webpackChunkName: "about" */ '../views/Solution.vue')
    },
    {
        path: '/mk1039',
        name: 'Mk1039',
        component: () => import(/* webpackChunkName: "about" */ '../views/Mk1039.vue')
    },
    {
        path: '/tax9698',
        name: 'Tax9698',
        component: () => import(/* webpackChunkName: "about" */ '../views/Tax9698.vue')
    },
    {
        path: '/product',
        name: 'Product',
        component: () => import(/* webpackChunkName: "about" */ '../views/Product.vue')
    },
    {
        path: '/industry',
        name: 'Industry',
        component: () => import(/* webpackChunkName: "about" */ '../views/Industry.vue')
    },
    {
        path: '/about',
        name: 'About',
        component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
    },
    {
        path: '/consult',
        name: 'Consult',
        component: () => import(/* webpackChunkName: "about" */ '../views/Consult.vue')
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
