<template>
  <div>
    <div class="home">
      <div class="banner position-relative">
        <div class="banner-content">
          <div class="banner-title">链接全球，贸易自由</div>

          <div class="banner-desc">
            用科技手段打破传统模式，用数字代码0和1重新定义全球贸易跨境新生态。帮助全球<br/>
            跨境贸易及资金通道打造快捷、高效、合规、平等及低成本的自由平台。<br/>
          </div>

          <el-button class="now-use-btn" @click.stop="openUrl('https://cb.shumeit.com/register')">立即启用</el-button>
        </div>
      </div>

      <div class="function">
        <div class="function-item flex-start" style="height: 572px">
          <div style="margin-right: 2px; flex-shrink: 0;margin-top: 120px">
            <div class="function-title">全球化收单与收款</div>
            <div class="function-desc">依托金融牌照，为企业提供全球的收单、收款、付款、对账管理等服<br/>
              务，完美解决企业出海遇到的一系列资金难题。
            </div>
            <div class="sub-title">多币种</div>
            <div class="function-desc">支持全球范围内广泛使用的多种货币收单、收款</div>
            <div class="sub-title">多区域</div>
            <div class="function-desc">具备全球性的服务渠道网络，覆盖北美、欧洲、亚洲、非洲、拉丁美洲<br/>等多个区域</div>
            <div class="sub-title">多支付方式</div>
            <div class="function-desc">支持信用卡、借记卡、电子钱包、货到付款等多种支付方式，满足不同<br/>地区支付习惯</div>
          </div>

          <img src="@/assets/images/home/world_map.png" style="height: 362.6px;margin-top: 131px" alt="">
        </div>


        <div class="gsth-box">
          <div class="function-item flex-column-center">
            <img src="@/assets/images/home/function_img2.svg" style="height:547px;position: absolute;z-index: 0" alt="">
            <div class="function-title">阳光跨境，关税汇一体化</div>
            <div class="function-desc">旨在通过报关、外汇、税务一体化的平台方案，有效提升出口业务的真实性、<br/>
              准确性和唯一性，帮助全球跨境贸易企业达到“降本”、“高效”、“合规”的目标。
            </div>

            <div class="gsth-card">
              <div class="space-between">
                <div class="card-item">
                  <div class="card-title">关</div>
                  <div class="card-desc">直连电子口岸，提供海关申报服务，帮助企业高效地完成出口货物的清关手续</div>
                </div>

                <div class="card-item">
                  <div class="card-title">税</div>
                  <div class="card-desc">
                    结合贸易新业态国内税务政策，提供税务咨询和筹划服务方案，帮助企业合规遵税，降低税务风险
                  </div>
                </div>

                <div class="card-item">
                  <div class="card-title">汇</div>
                  <div class="card-desc">
                    提供全球资金账户，帮助企业快速完成货款的本地币种收款、兑换和汇款。
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>

        <div class="function-item flex-start">
          <div style="margin-top: 76px; flex-shrink: 0">
            <div class="function-title">垂直产业进出口方案</div>
            <div class="function-desc">
              数美为客户量身打造场景化解决方案，赋能进出口企业建立专属的垂<br/>直产业链全球账户管理体系，集客户管理、交易管理以及资金收付/<br/>结汇功能于一体的综合解决方案。
            </div>

            <div class="space-between industry-plan">
              <div class="flex-double-center item-box">
                围绕垂直产业<br/>
                深度定制解决方案
              </div>
              <div class="flex-double-center item-box">
                多层级高效<br/>
                收付款与记账体系
              </div>
              <div class="flex-double-center item-box">
                一套账户完成<br/>
                全球资金收兑汇
              </div>
            </div>
          </div>

          <img src="@/assets/images/home/function_img3.svg" style="width:568px;margin-left: 36px" alt="">
        </div>
      </div>

      <div class="why-favour">
        <div class="favour-content">
          <div class="favor-title">数美为何深受跨境贸易企业青睐</div>
          <div class="favor-card flex-start">
            <div class="card-item">
              <div class="img-box flex-double-center">
                <img src="@/assets/images/home/favor_card1.svg"
                     style="width: 110px" alt="">
              </div>

              <div class="card-text">
                <div class="card-title">完备功能</div>
                <div class="card-desc">系统集成了收兑汇一体功能，能够同时满足客户不同的业务场景需求</div>
              </div>
            </div>

            <div class="card-item">
              <div class="img-box flex-double-center">
                <img src="@/assets/images/home/favor_card2.svg"
                     style="width: 136px" alt="">
              </div>

              <div class="card-text">
                <div class="card-title">操作便利</div>
                <div class="card-desc">全线上化流程，操作简单明了，帮助客户快速完成交易，提供资金使用效率</div>
              </div>
            </div>

            <div class="card-item">
              <div class="img-box flex-double-center">
                <img src="@/assets/images/home/favor_card3.svg"
                     style="width: 117px" alt="">
              </div>

              <div class="card-text">
                <div class="card-title">资金安全合规</div>
                <div class="card-desc">资金接受全球各地金融机构监管，防范资金风险，有效保障资金安全</div>
              </div>
            </div>

            <div class="card-item">
              <div class="img-box flex-double-center">
                <img src="@/assets/images/home/favor_card4.svg"
                     style="width: 136.5px" alt="">
              </div>

              <div class="card-text">
                <div class="card-title">科技赋能交易</div>
                <div class="card-desc">区块链技术实现全球数字化交易，保证数据真实性、唯一性和安全性</div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div class="partner flex-column-center">
        <div class="partner-title">
          战略合作伙伴
        </div>
        <img src="@/assets/images/home/home_partners.png"
             style="width: 950px;" alt="">
      </div>


    </div>
  </div>
</template>

<script>

export default {
  name: "home",
  data() {
    return {}
  },
  methods: {
    openUrl(url) {
      window.open(url, '_target');
    },
  },
}
</script>

<style scoped lang="scss">
.home {
  .banner {
    width: 100vw;
    background: url("~@/assets/images/home/home_banner2.png") no-repeat;
    background-size: cover;
    background-position: right;

    .banner-content {
      width: 1096px;
      margin: 0 auto;
      padding-top: 199px;
      padding-bottom: 80px;

      .banner-title {
        color: rgb(0, 0, 0);
        font-family: 'SourceHanSansCN-Regular';
        padding-bottom: 24px;
        font-size: 40px;
        font-weight: 700;
        line-height: 60px;
      }

      .banner-desc {
        color: rgb(34, 25, 25, 0.5);
        font-size: 18px;
        font-weight: 400;
        padding-bottom: 32px;
        line-height: 28px;
      }

      .now-use-btn {
        font-family: PingFang-SC-Regular;
        border-radius: 4px;
        background: rgb(0, 69, 156);
        color: #fff;
        border: 0;
        width: 104px;
        height: 40px;
        font-size: 16px;
      }

    }
  }

  .function {
    width: 100vw;

    .function-item {
      width: 1096px;
      margin: 0 auto;
      height: 472px;

      .function-title {
        font-family: SourceHanSansCN-Bold;
        color: rgb(0, 0, 0);
        font-size: 32px;
        line-height: 48px;
        margin-bottom: 24px;
      }

      .sub-title {
        color: rgb(0, 69, 156);
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        margin-bottom: 4px;
      }

      .function-desc {
        color: rgb(34, 25, 25);
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        margin-bottom: 16px;
      }
    }

  }

  .gsth-box {
    background: rgb(249, 249, 250);

    .function-item {
      height: 547px;
    }

    .gsth-card {
      width: 1096px;
      margin-top: 24px;

      .card-item {
        padding: 31px 20px;
        border-radius: 24px;
        margin-bottom: 32px;

        box-shadow: 0 30px 50px -35px rgba(8, 9, 48, 0.2), 0px 40px 80px -20px rgba(8, 9, 48, 0.1), 0px 0px 0px 1px rgba(8, 9, 48, 0.1);
        backdrop-filter: blur(5px);

        &:hover {
          background: rgba(218, 237, 255, 0.4);
        }

        .card-title {
          font-family: SourceHanSansCN-Bold;
          color: rgb(0, 69, 156);
          font-size: 24px;
          font-weight: 700;
          line-height: 36px;
          margin-bottom: 4px;
        }

        .card-desc {
          color: rgb(34, 25, 25);
          font-size: 18px;
          font-weight: 400;
          line-height: 24px;
          width: 304px;
        }
      }
    }
  }

  .industry-plan {
    .item-box {
      width: 144px;
      height: 144px;
      border-radius: 100px;
      border: 0.6px solid rgba(0, 0, 0, 0.15);

      color: rgb(0, 69, 156);
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      text-align: center;
    }
  }

  .why-favour {
    width: 100vw;
    background: rgb(32, 34, 37);

    .favour-content {
      padding-top: 80px;
      width: 1096px;
      margin: 0 auto;
      height: 512px;

      .favor-title {
        font-family: SourceHanSansCN-Bold;
        color: rgb(255, 255, 255);
        font-size: 32px;
        font-weight: 700;
        line-height: 48px;
        margin-bottom: 24px;
      }

      .favor-card {

        .card-item:last-child {
          margin-right: 0;
        }

        .card-item {
          width: 250px;
          height: 320px;
          border-radius: 24px;
          background: rgb(249, 249, 250);
          margin-right: 32px;

          .img-box {
            height: 188px;
          }

          .card-text {
            padding: 0 16px;

            .card-title {
              font-family: SourceHanSansCN-Bold;
              font-size: 24px;
              font-weight: 700;
              line-height: 36px;
              margin-bottom: 8px;
            }

            .card-desc {
              color: rgb(34, 25, 25);
              font-size: 16px;
              line-height: 24px;
            }
          }
        }
      }
    }
  }

  .partner {
    height: 399px;
    background: rgb(249, 249, 250);

    .partner-title {
      font-family: SourceHanSansCN-Regular;
      color: rgb(0, 0, 0);
      font-size: 28px;
      font-weight: 500;
      line-height: 42px;
      margin-bottom: 32px;
    }
  }
}
</style>