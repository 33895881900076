<template>
  <!-- 亚非拉外贸收款 -->
  <div class="solution">
    <div class="banner position-relative">
      <div class="banner-content">
        <div class="banner-title">亚非拉外贸收款</div>

        <div class="banner-desc position-relative">
          亚非拉，一触即达 —— 跨境收款，智胜未来
        </div>
      </div>

    </div>


    <div class="function">
      <div class="function-item flex-start-center" style="height: 520px">
        <div style="margin-right: 2px; flex-shrink: 0;margin-top: 120px">
          <div class="function-title">亚非拉本地收款网络</div>

          <div class="sub-title">本地银行账户，原币直收</div>
          <div class="function-desc">原币种直接入账，摆脱有限外汇额度束缚，让贸易更加高效自由。</div>
          <div class="sub-title">1个账户，掌握全球</div>
          <div class="function-desc">线上一键开户，仅需一个数美账户即可轻松管理多地区本地收款业务。</div>
          <div class="sub-title">资金瞬息通达</div>
          <div class="function-desc">资金快速到账，让您的资金触手可及，小币种换汇快人一步。</div>
        </div>

        <img src="@/assets/images/yflCollection/yfl_img1.png" style="height: 520px;margin-left: -20px" alt="">
      </div>


      <div style="background: rgb(249, 249, 250);">
        <div class="function-item flex-start position-relative" style="height: 472px">
          <img src="@/assets/images/yflCollection/yfl_img2.png" style="height: 472px;position: relative;left: -62px"
               alt="">

          <div style="margin: 76px 0 0 -62px; flex-shrink: 0">
            <div class="function-title">资金无界流通</div>
            <div class="sub-title">多币种自由兑换</div>
            <div class="function-desc">覆盖所有亚非拉本地小币种收款，轻松实现本地货币与全球主流货币<br/>自由兑换。</div>
            <div class="sub-title">超优汇率</div>
            <div class="function-desc">智能处理多方汇率，7*24小时超优外汇报价，降低您的汇兑成本。</div>
            <div class="sub-title">全球分发网络</div>
            <div class="function-desc">链接全球支付网络，覆盖资金流转全场景，让您的资金通达世界，助力<br/>全球业务拓展。
            </div>
          </div>
        </div>
      </div>


      <div class="function-item flex-start" style="height: 505px">
        <div style="margin-right: 2px; flex-shrink: 0;margin-top: 120px">
          <div class="function-title">技术驱动未来</div>

          <div class="sub-title">灵活的接入方式</div>
          <div class="function-desc">系统后台全线上操作，或API方式快速集成数美全部服务。</div>
          <div class="sub-title">多场景支付解决方案</div>
          <div class="function-desc">提供全球及本地的收付款服务，包括供应商付款、货币兑换、资金结<br/>汇等。针对全球业务需求，提供定制化的解决方案，适应各种业务场<br/>景。
          </div>
          <div class="sub-title">资金安全保障</div>
          <div class="function-desc">持牌合规经营，确保资金安全，同时系统使用反洗钱数据库进行7*<br/>24筛查，确保交易无风险。
          </div>
        </div>

        <img src="@/assets/images/yflCollection/yfl_img3.svg" style="position: relative;top: 40px;left: 40px" alt="">
      </div>
    </div>

    <div class="partner flex-column-center">
      <div class="partner-title">
        战略合作伙伴
      </div>
      <img src="@/assets/images/home/home_partners.png"
           style="width: 950px;" alt="">
    </div>


  </div>
</template>

<script>
export default {
  name: "solution",
  data() {
    return {}
  },
  methods: {},
}
</script>

<style scoped lang="scss">
.banner {
  width: 100vw;
  height: 400px;
  background: url("~@/assets/images/yflCollection/banner_bg.png");
  background-size: cover;
  background-position: right;

  .banner-content {
    width: 1096px;
    margin: 0 auto;
    padding-top: 160px;

    .banner-title {
      font-family: SourceHanSansCN-Bold;
      color: rgb(0, 0, 0);
      padding-bottom: 24px;
      font-size: 40px;
      font-weight: 700;
      line-height: 60px;
    }

    .banner-desc {
      color: rgb(34, 25, 25, 0.5);
      font-size: 18px;
      padding-bottom: 32px;
      line-height: 28px;
    }
  }
}

.function {
  width: 100vw;

  .function-item {
    width: 1096px;
    margin: 0 auto;
    height: 472px;

    .function-title {
      font-family: SourceHanSansCN-Bold;
      color: rgb(0, 0, 0);
      font-size: 32px;
      font-weight: 700;
      line-height: 48px;
      margin-bottom: 24px;
    }

    .sub-title {
      color: rgb(0, 69, 156);
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      margin-bottom: 4px;
    }

    .function-desc {
      color: rgb(34, 25, 25);
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      margin-bottom: 16px;
    }
  }
}

.partner {
  height: 399px;
  background: rgb(249, 249, 250);

  .partner-title {
    font-family: SourceHanSansCN-Regular;
    color: rgb(0, 0, 0);
    font-size: 28px;
    font-weight: 500;
    line-height: 42px;
    margin-bottom: 32px;
  }
}

</style>