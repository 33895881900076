<template>
  <div>
    <div class="about">

      <!--顶部      -->
      <div class="banner position-relative">
        <div class="banner-content">
          <div class="banner-title">关于数美</div>
        </div>
        <div class="banner-bg"/>
        <img src="@/assets/images/about/about_banner.svg"
             style="width: 1079px;position: absolute;right: 0;bottom: 0;z-index: -10" alt="">
      </div>

      <!--    跨境贸易描述  -->
      <div class="cb-trade">
        <div class="position-relative cb-trade-item">
          <div class="flex-start-end company-profile">
            <img src="@/assets/images/about/company-part-bg.png"
                 class="cb-trade-sub-item-image" alt="">

            <div class="cb-trade-sub-item-content"
                 style="display: flex;  flex-direction: column;justify-content: center;">

              <div style="padding-top: 15px">


                <div class="cb-trade-title">跨境贸易</div>
                <div class="cb-sub-title">一站式数字服务平台</div>

                <div class="cb-trade-desc">
                  <div>
                    数美自2020年创立，总部位于中国深圳，在新加坡/香港/许昌/成都/三亚等地设有分支机构或全资子公司。
                  </div>

                  <div style="margin-top: 30px">
                    香港全资子公司：数美信息科技有限公司，持有香港MSO牌照，与全球各大银行、支付金融机构长期合作，覆盖亚、非、拉50+个国家地区及30+种主流货币。
                  </div>

                  <div style="margin-top: 30px">
                    专注于为跨境贸易商家、跨境支付公司及跨境贸易服务商提供全球化的资金归集、换汇、汇款及财税一体化的合规方案；基于区块链底层的全球化智能换汇及清分网络，打造全球资金跨境的新生态。
                  </div>
                </div>
              </div>
            </div>
          </div>


        </div>

      </div>

      <!--咨询      -->
      <div style="margin-top: 480px" class="consult">

        <div class="image-container">
          <div class="overlay"></div>
          <img src="@/assets/images/about/cooperation.png" alt=""
               style="width:100vw; height: 360px; object-fit: cover;"/>

          <div class="centered-content">
            <img src="@/assets/images/about/shumei-logo.svg" alt=""
                 style="width:186px; height:32px; object-fit: cover;"/>
            <div class="text">开启贸易「新业态」</div>
            <button class="centered-button" @click="$router.push('/consult')">立即咨询</button>
          </div>
        </div>

      </div>

      <div class="contact" style="margin-bottom: 40px">
        <div class="contact-item">
          <div class="contact-content-item">
            <div class="contact-title">联系我们</div>
            <div class="company-title">深圳市数美信息科技有限公司</div>
            <div class="flex-start-end">
              <img src="@/assets/images/about/email-icon.svg"
                   class="contact-icon" style="width: 20px;margin-left: 2px" alt="">
              <div class="contact-email">service@shumeit.com</div>
            </div>

            <div class="contact-addr">
              <div class="flex-start">
                <img src="@/assets/images/about/addr-icon.svg"
                     class="contact-icon" alt="">
                <div style="margin-left: 8px">
                  <div>深圳市 南山区</div>
                  <div>粤海街道 海珠社区 兰芷二路66号</div>
                  <div>阳光保险大厦23楼</div>
                </div>
              </div>
            </div>
            <div class="contact-addr">
              <div class="flex-start">
                <img src="@/assets/images/about/addr-icon.svg"
                     class="contact-icon" alt="">
                <div style="margin-left: 8px">
                  <div>香港銅鑼灣軒尼詩道505號電業城14樓1410室</div>
                </div>
              </div>
            </div>
          </div>
          <div class="contact-content-img">
            <img src="@/assets/images/about/shumei-addr-map.svg" alt="">
          </div>
        </div>

      </div>


    </div>
  </div>
</template>

<script>

export default {
  name: "about",
  data() {
    return {}
  },
  methods: {
    openUrl(url) {
      window.open(url, '_target');
    },
  },
}
</script>

<style scoped lang="scss">
.about {
  .banner {
    width: 100vw;

    .banner-bg {
      background: linear-gradient(270.00deg, rgba(244, 248, 246, 0) 1.868%, rgb(241, 244, 247) 38.992%);
      width: 82.2vw;
      height: 400px;
      position: absolute;
      z-index: -1;
      top: 0;
    }

    .banner-content {
      width: 1096px;
      margin: 0 auto;
      padding-top: 160px;
      padding-bottom: 180px;
      z-index: -1;

      .banner-title {
        color: rgb(0, 0, 0);
        font-family: SourceHanSansCN-Bold;
        font-size: 40px;
        font-weight: 700;
        line-height: 60px;
      }


    }
  }


  .cb-trade {
    width: 100vw;

    .cb-trade-item {
      width: 1096px;
      margin: 0 auto;
      z-index: 3;

      .cb-trade-sub-item-image {
        position: absolute;
        top: -40px;
        z-index: 10;
        height: 560px;
        width: 548px;
        border-radius: 24px 0 0 24px;

        background: rgba(38, 56, 79, 0.1);
      }

      .cb-trade-title {
        color: rgb(0, 69, 156);
        font-family: SourceHanSansCN-Regular;
        font-weight: 900;
        font-size: 24px;
        line-height: 1px;
      }

      .cb-trade-sub-item-content {
        position: absolute;
        top: -40px;
        left: 548px;
        z-index: 10;
        height: 560px;
        width: 468px;
        margin: 0 auto;

        border-radius: 0 24px 24px 0;
        box-shadow: 0 30px 50px -35px rgba(8, 9, 48, 0.2), 0px 40px 80px -20px rgba(8, 9, 48, 0.1), 0px 0px 0px 1px rgba(8, 9, 48, 0.1);
        background: rgb(255, 255, 255);

        padding: 0 40px;
      }

      .cb-sub-title {
        font-size: 32px;
        font-family: SourceHanSansCN-Regular;
        font-weight: 900;
        line-height: 0;
        letter-spacing: 0;
        text-align: left;
        margin-top: 44px;
      }

      .cb-trade-desc {
        color: rgb(34, 25, 25);
        font-size: 16px;
        font-weight: 400;
        line-height: 26px;
        letter-spacing: 0;
        text-align: left;
        margin-top: 44px;
      }

    }

  }


  .consult {
    width: 100vw;

    .image-container {
      position: relative;
      display: inline-block;
      width: 100vw; /* 根据需要调整宽度 */
      height: 360px; /* 固定高度 */
      overflow: hidden; /* 防止内容溢出 */
    }

    .image-container img {
      width: 100vw;
      height: 100vh;
      object-fit: cover; /* 确保图片覆盖整个容器，保持宽高比 */
    }

    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(38, 56, 79, 0.8); /* 浅黑色背景，0.5是透明度 */
    }

    .centered-content {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); /* 居中定位 */
      z-index: 20; /* 确保内容在图片和背景之上 */
      display: flex; /* 使用Flexbox布局 */
      flex-direction: column; /* 垂直排列子元素 */
      align-items: center; /* 水平居中子元素 */
      margin-top: 19px;
    }

    .icon {
      width: 50px; /* 小图标的宽度 */
      height: 50px; /* 小图标的高度 */
      margin-bottom: 10px; /* 图标和文字之间的间距 */
    }

    .text {
      color: rgb(255, 255, 255);
      font-size: 40px;
      font-family: SourceHanSansCN-Regular;
      font-weight: 900;
      line-height: 60px;
      letter-spacing: 0;
      text-align: center;
      margin-top: 20px;
    }

    .centered-button {
      font-family: PingFang-SC-Regular;
      background-color: unset; /* 按钮背景色 */
      padding: 10px 20px; /* 按钮内边距 */
      border: 1px solid white; /* 无边框 */
      cursor: pointer; /* 鼠标悬停时显示指针 */
      border-radius: 10px; /* 圆角边框 */
      color: rgb(255, 255, 255);
      font-size: 24px;
      font-weight: 500;
      line-height: 34px;
      letter-spacing: 0;
      text-align: center;
      width: 160px;
      height: 58px;
      margin-top: 20px;
    }
  }


  .contact {
    width: 100vw;

    .contact-item {
      width: 1096px;
      height: 328px;
      margin: 0 auto;
      z-index: 5;
      flex-shrink: 0;
      display: flex;
      margin-top: 26px;

      .contact-content-item {
        width: 496px;
        height: 308px;

        .contact-icon {
          width: 24px;
          height: 24px;
        }

        .contact-title {
          margin-top: 60px;
          color: rgb(0, 69, 156);
          font-family: SourceHanSansCN-Regular;
          font-weight: 900;
          font-size: 24px;
          line-height: 36px;
          letter-spacing: 0;
          text-align: left;
        }

        .company-title {
          color: rgb(0, 0, 0);
          font-size: 32px;
          font-family: SourceHanSansCN-Regular;
          font-weight: 900;
          line-height: 48px;
          letter-spacing: 0;
          text-align: left;
        }

        .contact-email {
          margin-top: 27px;
          margin-left: 8px;
          color: rgb(34, 25, 25);
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 0;
          text-align: left;
        }

        .contact-addr {
          margin-top: 10px;
          color: rgb(34, 25, 25);
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 0;
          text-align: left;
        }
      }


      .contact-content-img {
        width: 600px;
        height: 328px;

        padding: 0;
        margin: 0;

        image {

          width: 100%;
          height: 100%;
          object-fit: contain; /* 确保图片覆盖整个容器，保持宽高比 */
        }
      }
    }


  }
}
</style>