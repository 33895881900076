<template>
  <div class="footer">
    <div class="footer-main space-between">
      <div class="nav flex-start">
        <div class="flex-column">
          <div class="nav-btn nav-title">产品中心</div>
          <el-button type="text" class="nav-btn" @click.stop="linkTo('/yfl/collection')">亚非拉收款</el-button>
          <el-button type="text" class="nav-btn" @click.stop="linkTo('/vi/chain')">垂直产业链</el-button>
          <el-button type="text" class="nav-btn" @click.stop="linkTo('/mk1039')">市场采购出口</el-button>
          <el-button type="text" class="nav-btn" @click.stop="linkTo('/tax9698')">跨境电商核定</el-button>
        </div>

        <div class="flex-column">
          <div class="nav-btn nav-title">关于数美</div>
          <el-button type="text" class="nav-btn" @click.stop="linkTo('/about')">企业简介</el-button>
          <el-button type="text" class="nav-btn" @click.stop="linkTo('/consult')">联系我们</el-button>
        </div>
      </div>

      <div class="chat-box">
        <div class="flex-end">
          <img src="@/assets/images/common/logo.svg" style="width: 186.4px;height: 32px;" alt="">
        </div>
        <div class="chat-info">
          <div>深圳市 南山区</div>
          <div>粤海街道 海珠社区 兰芷二路66号</div>
          <div>阳光保险大厦 2305-08</div>
        </div>

        <div class="chat-info">
          service@shumeit.com
        </div>
      </div>
    </div>

    <div class="copyright">
      <p class="icpBox">
        <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44030502006064">
          <img src="@/assets/images/icpbgs.png" class="footer-bottom-icpbg"/>
          <span style="color: #707072"> 粤公网安备44030502006064号</span>
        </a>
        &nbsp;|&nbsp;
        <a target="_blank" href="https://beian.miit.gov.cn/">
          <span style="color: #707072">粤ICP备20072242号-1</span>
        </a>
        &nbsp;|&nbsp;
        <span>Copyright © 数美{{ fullYear }}版权所有</span>
      </p>
    </div>
  </div>
</template>

<script>
// import request from '@/js/request.js';

export default {
  name: "Footer",
  data() {
    return {
      fullYear: (new Date().getFullYear()),
    }
  },
  methods: {
    linkTo(url) {
      if (url === this.$route.path) {
        document.documentElement.scrollTop = 0
        return
      }

      this.$router.push(url);
      document.documentElement.scrollTop = 0
      // request({
      //   url: url,
      //   method: 'get'
      // }).then(res => {
      //   if(res.code != 20000) {
      //     this.$emit('needLogin');
      //   } else {
      //     this.$router.push(url);
      //   }
      // })
    },
  }
}
</script>

<style lang="scss" scoped>
.footer {
  position: relative;
}

.footer-main {
  width: 1096px;
  margin: 0 auto;
  height: 320px;
  font-size: 16px;
  align-items: flex-start;
  padding-top: 80px;

  .nav {
    .nav-btn:first-child {
      margin-right: 90px;
    }

    .nav-btn {
      color: rgb(34, 25, 25, 0.8);
      font-family: 苹方-简;
      width: 96px;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      text-align: left;
      margin: 0;
      padding: 8px 0;
    }

    .nav-title {
      color: rgb(0, 69, 156);
      font-weight: 600;
      margin-bottom: 16px;
      padding: 0;
    }

    div {
      min-width: 60px;
      margin-bottom: 20px;
      color: #fff;
    }
  }

  .chat-box {
    .chat-info {
      margin-top: 16px;

      color: rgb(34, 25, 25, 0.8);
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      text-align: right;
    }

    div {
      .chat-title {
        display: inline-block;
        width: 80px;
        color: #707072;
      }
    }
  }
}

.copyright {
  position: absolute;
  bottom: 50px;
  width: 100%;
  text-align: center;
  color: #707072;
  letter-spacing: 2px;
}

.footer-bottom-icpbg {
  height: 15px;
  width: auto;
  vertical-align: middle;
}
</style>
