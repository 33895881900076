<template>
  <div class="mk1039">
    <div class="banner position-relative">
      <div class="banner-content position-relative">
        <div class="banner-title">数字美学</div>

        <div class="banner-desc">全球跨境贸易一站式数字服务平台先行者</div>

        <div class="consult-us">
          <div class="title">咨询我们</div>
          <div class="tip">请留下您的联系方式，我们会有专属顾问免费为您提供行业解决方案、产品咨询等服务</div>

          <el-form ref="form" :model="form" label-width="120px" label-position="top">
            <el-form-item label="企业名称" prop="companyName" :rules="rules.required">
              <template #label>
                <div class="position-relative">企业名称
                  <img src="@/assets/images/consult/asterisk.svg" alt="" class="asterisk"/>
                </div>
              </template>
              <el-input v-model="form.companyName" placeholder="请填写营业执照上的公司全称"/>
            </el-form-item>

            <el-form-item label="姓名" prop="customerName" :rules="rules.required">
              <template #label>
                <div class="position-relative">姓名
                  <img src="@/assets/images/consult/asterisk.svg" alt="" class="asterisk"/>
                </div>
              </template>
              <el-input v-model="form.customerName" placeholder="请填写您的姓名"/>
            </el-form-item>

            <el-form-item label="手机号" prop="mobileNo" :rules="rules.mobileNo">
              <template #label>
                <div class="position-relative">手机号
                  <img src="@/assets/images/consult/asterisk.svg" alt="" class="asterisk"/>
                </div>
              </template>
              <el-input v-model="form.mobileNo" placeholder="请填写您的手机号"/>
            </el-form-item>

            <el-form-item label="意向产品" prop="intendProducts" :rules="rules.required">
              <template #label>
                <div class="position-relative">意向产品
                  <img src="@/assets/images/consult/asterisk.svg" alt="" class="asterisk"/>
                </div>
              </template>
              <el-select v-model="form.intendProducts" multiple>
                <el-option v-for="(item, idx) in intendedProductList"
                           :key="idx" :label="item" :value="item"/>
              </el-select>
            </el-form-item>

            <el-form-item label="需求描述" prop="requireDesc" :rules="rules.required">
              <template #label>
                <div class="position-relative">需求描述
                  <img src="@/assets/images/consult/asterisk.svg" alt="" class="asterisk"/>
                </div>
              </template>
              <el-input type="textarea" v-model="form.requireDesc" placeholder="请描述您的经营品类、合作需求"/>
            </el-form-item>
          </el-form>

          <div class="flex-end">
            <el-button round class="submit-btn" @click="doSubmit">
              <div class="flex-double-center">提交
                <img src="@/assets/images/consult/arrow.svg" alt="" style="height: 20px;flex: 0;margin-left: 12px"/></div>
            </el-button>
          </div>
        </div>
      </div>

    </div>

    <div class="place-hold"/>
  </div>
</template>

<script>
import request from '@/js/request.js';

export default {
  name: "consult",
  data() {
    return {
      form: {},
      intendedProductList: ['外贸收款', '1039结汇', '9698税务核定', '云付汇'],
      rules: {
        required: {required: true, message: '必填', trigger: 'blur'},
        mobileNo: {required: true, message: '格式不正确', trigger: 'blur', pattern: /^1[3-9][0-9]{9}$/},
      }
    }
  },
  methods: {
    doSubmit() {
      this.$refs.form.validate()
          .then(() => this.$confirm('', '确认提交吗？'))
          .then(() => request.post('/officialWebsite/consultCreate', this.form))
          .then(() => {
            this.form = {}
            this.$message.success('提交成功，我们会有专属顾问免费为您提供行业解决方案、产品咨询等服务')
          })
    }
  },
}
</script>

<style scoped lang="scss">
.banner {
  width: 100vw;
  height: 800px;
  background-image: url("~@/assets/images/consult/banner_bg.png");
  background-size: cover;

  .banner-content {
    width: 1096px;
    margin: 0 auto;
    padding-top: 197px;

    .banner-title {
      color: rgb(255, 255, 255);
      font-family: SourceHanSansCN-Bold;
      font-size: 40px;
      font-weight: 700;
      line-height: 60px;
      padding-bottom: 8px;
    }

    .banner-desc {
      color: rgb(34, 25, 25, 0.5);
      font-size: 18px;
      font-weight: 400;
      line-height: 28px;
    }

    ::v-deep .consult-us {
      border-radius: 24px;
      box-shadow: 0 30px 50px -35px rgba(8, 9, 48, 0.2), 0 40px 80px -20px rgba(8, 9, 48, 0.1), 0 0 0 1px rgba(8, 9, 48, 0.1);
      background: #fff;
      padding: 32px;
      width: 484px;
      height: 662px;

      position: absolute;
      top: 160px;
      left: 548px;

      .title {
        color: rgb(0, 69, 156);
        font-family: SourceHanSansCN-Regular;
        font-weight: 900;
        font-size: 32px;
        line-height: 48px;
        margin: 8px 0 16px;
      }

      .tip {
        color: rgb(34, 25, 25);
        font-size: 16px;
        line-height: 26px;
        margin-bottom: 32px;
      }

      .asterisk {
        width: 7px;
        position: relative;
        top: -5px;
      }

      .el-form-item__label {
        color: rgba(8, 9, 48, 0.6);
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        padding-bottom: 4px;

        &:before {
          display: none;
        }
      }

      .el-form-item__content {
        line-height: 36px;
      }

      .el-input, .el-select {
        width: 100%;
      }

      .el-input__inner {
        height: 36px;
      }

      .submit-btn {
        border: 0;
        padding: 0;
        width: 110px;
        height: 46px;
        border-radius: 61px;
        background: rgb(0, 69, 156);
        margin-top: 10px;

        color: rgb(255, 255, 255);
        font-size: 24px;
        font-family: PingFang-SC-Regular;
        font-weight: 700;
        line-height: 48px;
      }
    }
  }
}

.place-hold {
  height: 166px;
  border-bottom: 1px solid #f9f9fa;
}
</style>