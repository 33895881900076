<template>
  <!-- 垂直产业链 -->
  <div class="mk1039">
    <div class="banner position-relative">
      <div class="banner-content">
        <div class="banner-title">垂直产业链</div>

        <div class="banner-desc">基于全球账户体系+场景化科技赋能，帮助贸易企业建立垂直产业链供应<br/>
          链体系，提供集客户管理、交易管理及资金收付汇一体的综合解决方案
        </div>
      </div>
    </div>

    <div class="challenge flex-column-center">
      <div class="title">垂直产业痛点及挑战</div>
      <div class="challenge-list flex-start-center">
        <div class="item">
          <img src="@/assets/images/viChain/challenge_img1.svg" style="width: 368px;" alt="">

          <div class="item-title">账户风险</div>
          <div class="item-desc">大量使用个人账户处理业务资金，账户面临巨<br/>大风险</div>
        </div>

        <div class="item">
          <img src="@/assets/images/viChain/challenge_img2.svg" style="width: 368px;" alt="">

          <div class="item-title">账务复杂</div>
          <div class="item-desc">手工记账，流水混乱且易出错，同时资金收付<br/>信息与业务不匹配</div>
        </div>

        <div class="item">
          <img src="@/assets/images/viChain/challenge_img3.svg" style="width: 368px;" alt="">

          <div class="item-title">收款难</div>
          <div class="item-desc">出口海外多个国家地区，对海外国家政策不熟<br/>悉，面临收款难、汇率差问题</div>
        </div>
      </div>
    </div>

    <div class="can-do">
      <div class="can-do-content flex-column-center">
        <div class="title">数美能为您做什么</div>

        <div class="can-do-item space-between">
          <div>
            <div class="item-title">垂直产业专业定制</div>
            <div class="item-desc">数美专业顾问团队，专门针对各类复杂垂直产业深入研究、解构，提出体系化解决方案</div>
          </div>

          <img src="@/assets/images/viChain/cando_img1.svg" style="position: relative;top: 40px" alt="">
        </div>

        <div class="can-do-item space-between">
          <img src="@/assets/images/viChain/cando_img2.svg" style="position: relative;left: -60px;top:30px" alt="">

          <div style="margin-left: -60px">
            <div class="item-title">多层级高效收款与记账</div>
            <div class="item-desc">支持从个体经营者到企业的全链条收款管理，建立透明、准确的<br/>账务体系
            </div>
          </div>
        </div>

        <div class="can-do-item space-between">
          <div>
            <div class="item-title">全球范围收款汇兑</div>
            <div class="item-desc">
              支持多种小币种的直接收款与汇兑服务，便捷、低成本的跨境支付解决方案，有效规避汇率波动风险，保障企业利润最大化
            </div>
          </div>

          <img src="@/assets/images/viChain/cando_img3.svg" style="position: relative;top: 40px" alt="">
        </div>
      </div>
    </div>


    <div class="customer-case">
      <div class="customer-case-content">
        <div class="case-card flex-column-center">
          <img src="@/assets/images/viChain/quotation_mark.svg" style="width: 24.8px;" alt="">
          <div class="comment">数美为我们提供了一整套资金收付定制服务，省心又省时，<br/>原本很复杂的账务变得简单清晰！
          </div>
          <img src="@/assets/images/viChain/customer_avatar.svg" style="width: 40px;" alt="">
          <div class="customer-name">万总</div>
          <div class="customer-position">中山资深外贸厂商</div>
        </div>

      </div>
    </div>

  </div>

</template>

<script>

export default {
  name: "mk1039",
  data() {
    return {}
  },
  methods: {},
}
</script>

<style scoped lang="scss">
.banner {
  width: 100vw;
  height: 400px;
  background-image: url("~@/assets/images/viChain/banner_bg.png");
  background-size: cover;
  background-position: right;

  .banner-content {
    width: 1096px;
    margin: 0 auto;
    padding-top: 160px;

    .banner-title {
      color: rgb(0, 0, 0);
      font-family: SourceHanSansCN-Bold;
      padding-bottom: 24px;
      font-size: 40px;
      font-weight: 700;
      line-height: 60px;
    }

    .banner-desc {
      color: rgb(34, 25, 25, 0.5);
      font-size: 18px;
      font-weight: 400;
      line-height: 28px;
    }
  }
}

.challenge {
  background: rgb(249, 249, 250);
  padding-bottom: 64px;

  .title {
    font-family: SourceHanSansCN-Regular;
    font-size: 32px;
    font-weight: 900;
    line-height: 48px;
    margin: 48px 0 32px;
  }

  .challenge-list {

    .item {
      background: #fff;
      border: 0.6px solid #e3e7eb;
      height: 376px;
      padding: 8px;
      margin: 0 32px;
      border-radius: 24px;
    }

    .item-title {
      color: rgb(16, 24, 40);
      font-size: 24px;
      font-weight: 600;
      line-height: 32px;
      text-align: center;
      margin-top: 20px;
      margin-bottom: 8px;
    }

    .item-desc {
      width: 100%;
      color: rgb(34, 25, 25);
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      text-align: center;
    }


  }
}

.can-do {
  padding-bottom: 48px;

  .can-do-content {
    margin: 0 auto;

    .title {
      font-family: SourceHanSansCN-Regular;
      color: rgb(0, 0, 0);
      font-size: 32px;
      font-weight: 900;
      line-height: 48px;
      margin: 48px;
    }

    .can-do-item {
      width: 1040px;
      height: 328px;

      .item-title {
        color: rgb(0, 69, 156);
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        margin-bottom: 4px;
      }

      .item-desc {
        width: 484px;
        color: rgb(34, 25, 25);
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
      }
    }
  }
}


.customer-case {
  background: rgb(249, 249, 250);

  .customer-case-content {
    width: 1096px;
    margin: 0 auto;
    padding: 83px 0 80px;
    display: flex;
    align-items: center;

    .case-card {
      width: 100%;
      height: 310px;

      border-radius: 24px;
      box-shadow: 0 30px 50px -35px rgba(8, 9, 48, 0.2), 0 40px 80px -20px rgba(8, 9, 48, 0.1), 0 0 0 1px rgba(8, 9, 48, 0.1);

      .comment {
        font-size: 32px;
        font-weight: 500;
        line-height: 48px;
        text-align: center;
        margin: 12px 0 16px;
      }

      .customer-name {
        font-size: 16px;
        font-weight: 500;
        color: rgb(0, 69, 156);
        margin: 8px 0 0;
      }

      .customer-position {
        font-size: 12px;
        color: rgba(34, 25, 25, 0.6);
      }
    }
  }
}
</style>