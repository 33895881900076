<template>
  <div class="solution">
    <div class="banner position-relative">
      <div class="banner-content">
        <div class="banner-title">全球数字化贸易专家</div>

        <div class="banner-desc position-relative">
          致力于一站式为商户提供多样化解决方案，以破解全球跨境贸易难题，<br/>助力企业实现合规经营，降本增效，让全球贸易之路更加顺畅无阻！

          <img src="@/assets/images/solution/gsth.svg"
               style="width: 1251.1px;position: absolute;left: -142px;top: 142px;" alt="">
        </div>
      </div>

      <div class="banner-bg"/>
      <img src="@/assets/images/solution/banner_bg.svg"
           style="width: 1145px;position: absolute;right: 0;top: 0;z-index: -10" alt="">
    </div>

    <div class="gsth position-relative">
      <div class="gsth-content">
        <div class="gsth-title flex-double-center"><span style="color: rgb(0, 69, 156)">「关、税、退、汇」</span>一体化解决方案
        </div>
        <div class="gsth-desc flex-double-center">旨在通过一体化的平台方案，提升业务效率、真实性、准确性，避免<br/>信息孤岛，帮助全球跨境贸易企业达到“降本”、“合规”的目标
        </div>

        <div class="gsth-card">
          <div class="space-between">
            <div class="card-item">
              <div class="card-title">关</div>
              <div class="card-desc">直连电子口岸，提供高效海关申报服务，帮助企业高效地完成进出口货物的清关手续</div>
            </div>

            <div class="card-item">
              <div class="card-title">税</div>
              <div class="card-desc">
                结合贸易新业态国内税务政策，提供税务咨询和筹划服务方案，帮助企业合规遵税，降低税务风险
              </div>
            </div>
          </div>

          <div class="space-between">
            <div class="card-item">
              <div class="card-title">退</div>
              <div class="card-desc">
                结合报关单与进项发票，智能匹配推送对应税务局，确保企业及时、准确获得应得的退税款项，提高资金利用效率
              </div>
            </div>

            <div class="card-item">
              <div class="card-title">汇</div>
              <div class="card-desc">
                全球资金账户，集合主流地区支付牌照，帮助企业快速完成货款的收付，确保贸易资金的安全并提高资金效率
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div class="ai-model">
      <div class="ai-model-content">
        <div class="ai-model-title"><span style="color: rgb(127, 192, 251)">AI大模型</span>智能决策系统</div>
        <div class="ai-model-desc">通过利用人工智能技术和规模机器学习模型，帮助用户优化和提升<br/>电商平台的运营效率、提升商品销量和降低运营成本
        </div>

        <div class="position-relative">
          <div class="specific-title">海量数据分析</div>
          <div class="specific-desc">
            AI大模型通过分析整体市场波动、平台品类销售情况和客户消费习惯和潜在需求。让电商客户更精准地了解市场行情和理解消费，为而制定更有效的营销策略提供参考
          </div>

          <div class="specific-title">制定营销策略</div>
          <div class="specific-desc">结合AI生成的大模型，智能化帮客户生成适合客户场景的营销策略以及生成智能投放策略</div>

          <div class="specific-title">实时调整与优化</div>
          <div class="specific-desc">
            营销策略投放后根据实时市场情况进行动态调整和优化策略。确保营销策略能够投入少、收益多和库存少，帮助客户提升运营效率，从而在激烈的市场竞争中获得优势
          </div>

          <img src="@/assets/images/solution/model_img.svg"
               style="width: 668px;;position: absolute;left: 505px;top: -80px;" alt="">
        </div>
      </div>
    </div>


    <div class="supply-chain">
      <div class="supply-chain-content position-relative">
        <img src="@/assets/images/solution/chain_bg.svg"
             style="width: 1376px;;position: absolute;left: -140px;top: -32px;z-index: -1" alt="">


        <div class="title"><span style="color: rgb(0, 69, 156)">全球供应链金融</span>解决方案</div>
        <div class="desc">利用区块链技术确保交易数据的真实性、唯一性和安全性，为全球贸易企业<br/>提供更加便利的授信服务。优化供应链管理效率助于企业实现增效目标
        </div>

        <div class="position-relative">
          <div class="specific-title">海量数据分析</div>
          <div class="specific-desc">
            AI大模型通过分析整体市场波动、平台品类销售情况和客户消费习惯和潜在需求。让电商客户更精准地了解市场行情和理解消费，为而制定更有效的营销策略提供参考
          </div>

          <div class="specific-title">制定营销策略</div>
          <div class="specific-desc">结合AI生成的大模型，智能化帮客户生成适合客户场景的营销策略以及生成智能投放策略</div>

          <div class="specific-title">实时调整与优化</div>
          <div class="specific-desc">
            营销策略投放后根据实时市场情况进行动态调整和优化策略。确保营销策略能够投入少、收益多和库存少，帮助客户提升运营效率，从而在激烈的市场竞争中获得优势
          </div>

        </div>
      </div>
    </div>

    <div class="our-product">
      <div class="our-product-content">
        <div class="title">我们的产品</div>

        <div class="space-between">
          <div class="card-item">
            <div class="item-title">市场采购阳光出口</div>
            <div class="item-desc"><span style="color: rgb(0, 69, 156)">1039</span>市场采购贸易经营户可依法享受税收优惠政策
            </div>
          </div>

          <div class="card-item">
            <div class="item-title">跨境电商核定</div>
            <div class="item-desc">以<span style="color: rgb(0, 69, 156)">9610，9810</span>模式出口申报的跨境电商<br/>
              提供以企业所得税核定为基础的一站式服务，按照4%核定所得额
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="consult">
      <div class="consult-content flex-column-center">
        <img src="@/assets/images/common/logo2.svg" style="height: 32px;" alt="">
        <div class="text">开启贸易「新业态」</div>

        <el-button class="consult-btn">立即咨询</el-button>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "solution",
  data() {
    return {}
  },
  methods: {},
}
</script>

<style scoped lang="scss">
.banner {
  width: 100vw;
  height: 535px; //todo

  .banner-bg {
    background: linear-gradient(270.00deg, rgba(244, 248, 246, 0) 27.029%, rgb(241, 244, 247) 67.872%);
    width: 80.3vw;
    height: 535px;
    position: absolute;
    z-index: -1;
    top: 0;
  }

  .banner-content {
    width: 1096px;
    margin: 0 auto;
    padding-top: 160px;
    padding-bottom: 80px;

    .banner-title {
      color: rgb(0, 0, 0);
      font-family: Source Han Sans CN;
      padding-bottom: 24px;
      font-size: 40px;
      font-weight: 700;
      line-height: 60px;
    }

    .banner-desc {
      color: rgb(34, 25, 25, 0.5);
      font-size: 18px;
      padding-bottom: 32px;
      line-height: 28px;
    }
  }
}

.gsth {
  width: 100vw;
  height: 626px; //todo
  //z-index: 1;

  .gsth-content {
    width: 1096px;
    margin: 0 auto;

    .gsth-title {
      font-family: 思源黑体 CN;
      font-size: 32px;
      font-weight: 700;
      line-height: 48px;
      margin-top: -16px;
      margin-bottom: 18px;
    }

    .gsth-desc {
      color: rgb(34, 25, 25);
      font-family: 苹方-港;
      font-size: 16px;
      line-height: 26px;
      text-align: center;
      margin-bottom: 96px;
    }

    .gsth-card {
      width: 1096px;

      .card-item {
        padding: 31px 20px;
        border-radius: 24px;
        margin-bottom: 32px;

        box-shadow: 0 30px 50px -35px rgba(8, 9, 48, 0.2), 0px 40px 80px -20px rgba(8, 9, 48, 0.1), 0px 0px 0px 1px rgba(8, 9, 48, 0.1);
        backdrop-filter: blur(5px);

        &:hover {
          background: rgba(218, 237, 255, 0.4);
        }

        .card-title {
          color: rgb(0, 69, 156);
          font-family: 思源黑体 CN;
          font-size: 24px;
          font-weight: 700;
          line-height: 36px;
          margin-bottom: 4px;
        }

        .card-desc {
          color: rgb(34, 25, 25);
          font-family: 苹方-港;
          font-size: 18px;
          font-weight: 400;
          line-height: 24px;
          width: 492px;
        }
      }
    }
  }

}


.ai-model {
  width: 100vw;
  height: 658px;
  background-image: url("~@/assets/images/solution/model_bg.png");
  background-size: cover;

  .ai-model-content {
    width: 1096px;
    margin: 0 auto;
    padding-top: 80px;

    .ai-model-title {
      font-family: 思源黑体 CN;
      color: rgb(245, 245, 245);
      font-size: 32px;
      font-weight: 700;
      line-height: 48px;
      text-align: center;
      margin-bottom: 18px;
    }

    .ai-model-desc {
      color: rgb(245, 245, 245);
      font-family: 苹方-港;
      font-size: 16px;
      font-weight: 400;
      line-height: 26px;
      text-align: center;
      margin-bottom: 40px;
    }

    .specific-title {
      color: rgb(127, 192, 251);
      font-family: 苹方-港;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      margin-bottom: 4px;
    }

    .specific-desc {
      width: 484px;
      color: rgb(245, 245, 245);
      font-family: 苹方-港;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      margin-bottom: 16px;
    }
  }
}

.supply-chain {
  width: 100vw;
  height: 758px;

  .supply-chain-content {
    width: 1096px;
    margin: 0 auto;
    padding-top: 80px;

    .title {
      font-family: 思源黑体 CN;
      font-size: 32px;
      font-weight: 700;
      line-height: 48px;
      text-align: center;
      margin-bottom: 18px;
    }

    .desc {
      color: rgb(34, 25, 25);
      font-family: 苹方-港;
      font-size: 16px;
      line-height: 26px;
      text-align: center;
      margin-bottom: 40px;
    }

    .specific-title {
      color: rgb(127, 192, 251);
      font-family: 苹方-港;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      margin-bottom: 4px;
    }

    .specific-desc {
      width: 484px;
      color: rgb(34, 25, 25);
      font-family: 苹方-港;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      margin-bottom: 16px;
    }
  }
}

.our-product {
  width: 100vw;
  height: 440px;
  background: rgb(249, 249, 250);

  .our-product-content {
    width: 1096px;
    margin: 0 auto;
    padding-top: 40px;

    .title {
      color: rgb(0, 0, 0);
      font-family: 思源黑体 CN;
      font-size: 32px;
      font-weight: 700;
      line-height: 48px;
      margin-bottom: 24px;
    }

    .card-item:last-child {
      background-image: url("~@/assets/images/solution/product_bg2.svg");
    }

    .card-item {
      width: 468px;
      height: 100px;
      border-radius: 24px;
      background-image: url("~@/assets/images/solution/product_bg1.svg");
      background-size: cover;
      padding: 74px 32px;

      .item-title {
        color: rgb(0, 0, 0);
        font-family: 思源黑体 CN;
        font-size: 32px;
        font-weight: 700;
        line-height: 48px;
        margin-bottom: 4px;
      }

      .item-desc {
        color: rgba(34, 25, 25, 0.6);
        font-family: 苹方-港;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
      }
    }

  }

}

.consult {
  width: 100vw;
  height: 360px;
  background-image: url("~@/assets/images/solution/consult_bg.svg");
  background-size: cover;

  .consult-content {
    width: 1096px;
    margin: 0 auto;
    padding-top: 99px;
    color: rgb(255, 255, 255);

    .text {
      font-family: 思源黑体 CN;
      font-size: 40px;
      font-weight: 700;
      line-height: 60px;
      margin-top: 12px;
      margin-bottom: 32px;
    }

    .consult-btn {
      width: 160px;
      height: 58px;
      padding: 0;
      background: transparent;

      color: rgb(255, 255, 255);
      font-size: 24px;
      font-weight: 500;
      line-height: 34px;
      border-radius: 12px;
    }
  }
}
</style>