<template>
  <div class="industry">
    <div class="banner">
      <img src="@/assets/pic_banner4.png" alt="">
    </div>
<!--    <div class="introduction">-->
<!--      <div class="text">-->
<!--        金融机构-->
<!--      </div>-->
<!--      <div class="sub-text">-->
<!--        利用金融机构最底层的资金处理能力，桥接商户和银行，通过账本记账功能能够更加贴合商户的业务需求场景，降低商户运营成本，同时资金全部托管在银行账户，保证资金安全合规。商户资金处理效率更好，降低运营成本。-->
<!--      </div>-->
<!--    </div>-->
    <div class="industry-container">
<!--      <div class="title">-->
<!--        非金融机构-->
<!--      </div>-->

      <div class="industry-list" style="justify-content: start">
        <div class="industry-item">
          <div class="industry-item-title">
            一般贸易
          </div>
          <div class="industry-item-card">
            <img src="@/assets/pic_icon.png" alt="">
            <div class="industry-item-text">
              帮助客户进行0110海关申报，通过银行进行收汇并开展出口退税
            </div>
          </div>
        </div>
        <div class="industry-item">
          <div class="industry-item-title">
            进口电商
          </div>
          <div class="industry-item-card">
            <img src="@/assets/pic_icon4.png" alt="">
            <div class="industry-item-text">
              为进口电商提供收单、跨境付款、海关推单、换汇分发完整资金链路的综合解决方案，解决进口电商资金烦扰。
            </div>
          </div>
        </div>
      </div>

      <div class="industry-list">
        <div class="industry-item">
          <div class="industry-item-title">
            9610出口
          </div>
          <div class="industry-item-card">
            <img src="@/assets/pic_icon2.png" alt="">
            <div class="industry-item-text">
              帮助出口电商客户进行9610出口申报，海外销售资金回款后进行税务核定或者出口退税。
            </div>
          </div>
        </div>
        <div class="industry-item">
          <div class="industry-item-title">
            9710出口
          </div>
          <div class="industry-item-card">
            <img src="@/assets/pic_icon2.png" alt="">
            <div class="industry-item-text">
              帮助客户按9710方式报关出口申报，海外销售资金回款并申报退税
            </div>
          </div>
        </div>
        <div class="industry-item">
          <div class="industry-item-title">
            9810出口
          </div>
          <div class="industry-item-card">
            <img src="@/assets/pic_icon2.png" alt="">
            <div class="industry-item-text">
              帮助出口电商进行9810海外仓模式出口，并通过银行进行海外销售资金回款
            </div>
          </div>
        </div>
      </div>

<!--      <div class="industry-list">-->
<!--        <div class="industry-item">-->
<!--          <div class="industry-item-title">-->
<!--            B2B行业-->
<!--          </div>-->
<!--          <div class="industry-item-card">-->
<!--            <img src="@/assets/pic_icon.png" alt="">-->
<!--            <div class="industry-item-text">-->
<!--              一般贸易-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="industry-item">-->
<!--          <div class="industry-item-title">-->
<!--            B2C覆盖行业-->
<!--          </div>-->
<!--          <div class="industry-item-card">-->
<!--            <img src="@/assets/pic_icon2.png" alt="">-->
<!--            <div class="industry-item-text">-->
<!--              帮助商户从海外收单、换汇、资金入境，境内分发完整资金解决方案，协助商户将业务快速拓展到全球范围-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="industry-item">-->
<!--          <div class="industry-item-title">-->
<!--            C2C行业-->
<!--          </div>-->
<!--          <div class="industry-item-card">-->
<!--            <img src="@/assets/pic_icon3.png" alt="">-->
<!--            <div class="industry-item-text">-->
<!--              帮助个人简单、快速、低成本将资金汇出境外个人账户-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->

<!--      <div class="industry-list">-->
<!--        <div class="industry-item">-->
<!--          <div class="industry-item-title">-->
<!--            C2B行业-->
<!--          </div>-->
<!--          <div class="industry-item-card">-->
<!--            <img src="@/assets/pic_icon4.png" alt="">-->
<!--            <div class="industry-item-text">-->
<!--              为进口电商提供收单、跨境付款、海关推单、换汇分发完整资金链路的综合解决方案，解决进口电商资金烦扰。-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="industry-item">-->
<!--          <div class="industry-item-title hidden">-->

<!--          </div>-->
<!--          <div class="industry-item-card">-->
<!--            <img src="@/assets/pic_icon5.png" alt="">-->
<!--            <div class="industry-item-text">-->
<!--              支持出口电商在不同国家开设银行账户，本地收单、换汇、资金入境等全流程服务，助力电商企业拓展全球化市场。-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="industry-item">-->
<!--          <div class="industry-item-title hidden">-->

<!--          </div>-->
<!--          <div class="industry-item-card">-->
<!--            <img src="@/assets/pic_icon6.png" alt="">-->
<!--            <div class="industry-item-text">-->
<!--              能够方便快速将学生费用汇出到国外学校账户-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="industry-list none-title">-->
<!--        <div class="industry-item">-->
<!--          <div class="industry-item-title none">-->

<!--          </div>-->
<!--          <div class="industry-item-card">-->
<!--            <img src="@/assets/pic_icon7.png" alt="">-->
<!--            <div class="industry-item-text">-->
<!--              帮助在线教育平台将薪资付给海外老师，低汇损、快速到账、支付付款国家多。-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="industry-item">-->
<!--          <div class="industry-item-title none">-->

<!--          </div>-->
<!--          <div class="industry-item-card">-->
<!--            <img src="@/assets/pic_icon8.png" alt="">-->
<!--            <div class="industry-item-text">-->
<!--              为出境旅游行业的商户提供一整套的资金解决方案，能够帮助商户更加便利、高效、合规、低成本的运营。-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="industry-item">-->
<!--          <div class="industry-item-title none">-->

<!--          </div>-->
<!--          <div class="industry-item-card">-->
<!--            <img src="@/assets/pic_icon9.png" alt="">-->
<!--            <div class="industry-item-text">-->
<!--              解决软件或者游戏平台商户全球范围内的本地支付问题，同时能够低成本快速地将资金分发到全球。-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
    </div>
  </div>
</template>

<script>

  export default {
    name: 'Industry',
  }
</script>

<style lang="scss" scoped>
  .industry {
    width: 100%;
  }
  .banner {
    position: relative;
    background: #FCFCFC;
    img {
      width: 100%;
    }
  }
  .introduction {
    padding: 90px 260px;
    background: #FCFCFC;
    text-align: center;
    .text {
      margin-bottom: 20px;
      font-size: 32px;
      color: #333333;
      line-height: 36px;
    }
    .sub-text {
      font-size: 16px;
      color: #666666;
      line-height: 32px;
    }
  }

  .industry-container {
    padding: 100px 260px 0;
    .title {
      margin-bottom: 80px;
      font-size: 32px;
      text-align: center;
      color: #333333;
      line-height: 36px;
    }
    .industry-list {
      display: flex;
      justify-content: space-between;
      margin-bottom: 60px;
      &.none-title {
        margin-top: 80px;
      }
      &:last-of-type {
        margin-bottom: 100px;
      }
      .industry-item {
        min-width: 400px;
        max-width: 20vw;
        .industry-item-title {
          position: relative;
          height: 60px;
          box-sizing: border-box;
          margin-bottom: 40px;
          padding-top: 12px;
          font-size: 24px;
          color: #333333;
          line-height: 48px;
          &::before {
            position: absolute;
            top: 0;
            display: inline-block;
            width: 30px;
            height: 3px;
            content: '';
            background: #52BF63;
          }
          &.hidden {
            visibility: hidden;
          }
          &.none {
            display: none;
          }
        }
        .industry-item-card {
          max-width: 400px;
          width: 20vw;
          font-size: 0;
          img {
            max-width: 400px;
            width: 20vw;
          }
          .industry-item-text {
            display: flex;
            width: 100%;
            height: 200px;
            justify-content: center;
            align-items: center;
            box-sizing: border-box;
            padding: 50px;
            font-size: 14px;
            color: #666666;
            line-height: 32px;
            background: #FBFBFB;
          }
        }
      }
    }
  }

  @media screen and (max-width: 1440px) {
    .industry-item {
      width: 250px;
      overflow: hidden;
      .industry-item-card {
        width: 100%;
      }
      img, .industry-item-text {
        width: 250px !important;
      }
    }
  }
</style>
